<template>
  <div></div>
</template>
<script type="plain/text">
import LoginService from "@/scripts/services/login.service";
import UserService from "@/scripts/services/user.service";
import CartService from "@/scripts/services/cart.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import LoginResultType from "@/scripts/models/enums/loginResultType.enum";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      userService: new UserService(),
      loginService: new LoginService(),
      cartService: new CartService(),
    };
  },
  created() {
    if (this.$route.query.token != null) this.login(this.$route.query.token);
    else this.$router.push({ name: Routes.app.CampaignLandingPage });
  },
  computed: {
    ...mapGetters(["cartItems", "cartLocal", "logged", "reloadCart"]),
  },
  methods: {
    ...mapActions(["setLogged", "setReloadCart", "updateCart"]),
    login(token) {
      this.loginService
        .loginByToken(token)
        .then(this.onLogin.bind(this))
        .catch(this.onLoginFailed.bind(this));
    },
    onLogin(loginResult) {
      if (loginResult.success == LoginResultType.Success) {
        this.$store.commit("REMOVE_ROLES");
        this.userService.listRolesOfLoggedUser().then(
          function (data) {
            this.$store.commit("SET_ROLES", data);
            this.saveCartitems();
            this.setLogged(true);
            this.setReloadCart(true);
            this.$router.push({ name: Routes.web.CartLogged });
          }.bind(this)
        );
      } else {
        this.$router.push({ name: Routes.app.CampaignLandingPage });
      }
    },
    onLoginFailed: function (baseResponse) {
      this.setLogged(false);
      CommonHelper.swal(baseResponse.errorMessage);
    },
    saveCartitems() {
      if (this.reloadCart) this.setReloadCart(false);
      let items = this.cartItems.map((item) => {
        if (item.donationProduct) {
          return {
            projectId: item.project.id,
            donationProductId: item.donationProduct.id,
          };
        } else {
          return {
            projectId: item.project.id,
          };
        }
      });

      this.cartService.mergeCartItems(items).then(
        function (data) {

          // Add campaign product if exist
          data.campaignProduct = this.cartLocal.campaignProduct
          data.campaignProductId = this.cartLocal.campaignProductId

          if (data && data.cartItems) {
            this.updateCart(data);
          }
        }.bind(this)
      );
    },
  },
};
</script>